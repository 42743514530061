<template>
<div>
    <!-- Top Domain Details -->
    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="domain-info mb-8">
                <!-- Domain -->
                <select-filter labelname="Domain" :options="domainOptions" optionsName="domainName" :value="domainSelected" @input="(newDomain) => {domainSelected = newDomain}"></select-filter>
                <!-- Date Picker -->
                <date-range-picker :fromDateValue="fromDate" :toDateValue="toDate" @inputFromDate="(newFromDate) => {fromDate = newFromDate}" @inputToDate="(newToDate) => {toDate = newToDate}"></date-range-picker>
                <!-- Only Companies checkbox -->
                <only-company :onlyCompaniesValue="onlyCompanies" @input="(newonlyCompanies) => {onlyCompanies = newonlyCompanies}"></only-company>
                <!-- Update -->
                <vs-button type="filled" class="small-btn bg-actionbutton" @click="updateData()">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
            </div>
        </div>
    </div>
    <!-- Exit Pages And Bounce Rate -->
    <div class="vx-row">
        <entry-exit-page-table
            @refDataInput="(newRefData) => {refData = newRefData;}"
            :tableTitle="'Exit Pages and Bounce Rate'"
            :perPageValue="perPage"
            @perPageInput="(newPerPage) => {perPage = newPerPage;}"
            :recordList="recordList"
            :tableRecords="exitPageData"
            :columnNameArray="['Exit Page', 'Exits', 'Exit %', 'Bounces', 'Bounce %', 'Bounce Rate']"
            :columnSortKeyArray="['Page', 'Page_Count', 'sortablePagePercent', 'Bounce_Count', 'sortableBouncePercent', 'sortableBounceRatePercent']"
            @clickToGetDetailData="getDetailedData"
            :entryPageOrExitPage="'ExitPage'"
        ></entry-exit-page-table>
    </div>

    <!-- Analytics Modal -->
    <analytics-view-information-modal
        :popupTitle="'Analytics - ' + category+ ' : ' + subCategory"
        :isOpen="analyticsActive"
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :ISPIncludeMessage="ISPIncludeMessage"
        :zoneName="zoneName"
        
        :singleCardOneIcon="'UserIcon'"
        :singleCardOneSmallTitle="'Unique Visitors'"
        :singleCardOneBottomText="'From '+analyticsUniqueVisitors.startValue+' to '+ analyticsUniqueVisitors.endValue +' unique visitors'"
        :singleCardOneUpDownValue="analyticsUniqueVisitors.percentage"
        :singleCardOneUpicon="'ArrowDownIcon'"
        :singleCardOneBgcolor="'bg-first-gradient'"
        :singleCardOneMainTextColor="'text-first'"
        :singleCardOneTextColor="'text-secondary'"
        :singleCardOneBGImg="'user.svg'"

        :singleCardTwoIcon="'EyeIcon'"
        :singleCardTwoSmallTitle="'Page Views'"
        :singleCardTwoBottomText="'From '+ pageViews.startValue +' to '+ pageViews.endValue +' Page Views'"
        :singleCardTwoUpDownValue="pageViews.percentage"
        :singleCardTwoUpicon="'ArrowDownIcon'"
        :singleCardTwoBgcolor="'bg-second-gradient'"
        :singleCardTwoMainTextColor="'text-second'"
        :singleCardTwoTextColor="'text-danger'"
        :singleCardTwoBGImg="'eye.png'"

        :chartTitle="'Changes Over this Period'"
        :chartType="'line'"
        :chartHeight="'350'"
        :seriesNameOne="'Unique Visitors'"
        :seriesDataOne="lineAreaChartSplineSeriesDataOne"
        :seriesNameTwo="'Page Views'"
        :seriesDataTwo="lineAreaChartSplineSeriesDataTwo"
        :optionsXaxis="lineAreaChartSplineOptionsXaxis"

        @clickToClosePopup="analyticsActive = false"
    ></analytics-view-information-modal>

    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";
import HelpModal from "../../components/modal/HelpModal";
import vSelect from "vue-select";
import EntryExitPageTable from '../../components/analytics/EntryExitPageTable.vue';
import AnalyticsViewInformationModal from '../../components/modal/AnalyticsViewInformationModal.vue';

export default {
    components: {
        VxCard,
        SelectFilter,
        DateRangePicker,
        OnlyCompany,
        HelpModal,
        vSelect,
        EntryExitPageTable,
        AnalyticsViewInformationModal
    },
    data() {
        return {
            refData: null,
            /* Domain Filter */
            domainSelected: {
                domainName: "All"
            },
            //Domain dropdown options
            domainOptions: [],
            //From Date
            fromDate: null,
            //To Date
            toDate: null,
            //Only Companies
            onlyCompanies: false,

            /* Analytics Popup  */
            analyticsActive: false,
            ISPIncludeMessage: "",
            dateStart: null,
            dateEnd: null,
            zoneName: null,
            category: null,
            subCategory: null,
            analyticsUniqueVisitors: {
                name: null,
                startValue: 0,
                endValue: 0,
                percentage: null
            },
            pageViews: {
                name: null,
                percentage: null,
                startValue: 0,
                endValue: 0
            },

            popupActive: false,
            /* Table */
            exitPageData: [],
            /* table select */
            perPage: 5,
            recordList: [{
                    text: '5',
                    value: 5
                },
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '25',
                    value: 25
                },
                {
                    text: '50',
                    value: 50
                },
                {
                    text: '100',
                    value: 100
                }
            ],
            /* Analytics popup Chart */
            lineAreaChartSplineSeriesDataOne: [],
            lineAreaChartSplineSeriesDataTwo: [],
            lineAreaChartSplineOptionsXaxis: []
        };
    },
    created() {
        this.setFromDate();
        this.setToDate();
        this.getZoneListForFilter();
        this.getExitPageData();
    },
    methods: {
        closepopup() {
            this.popupActive = false;
        },
        updateData() {
            this.refData.currentx = 1
            this.refData.searchx = ""
            this.getExitPageData();
        },
        //Get Domain
        async getZoneListForFilter() {
            this.$vs.loading();
            await this.axios
                .get("/ws/Zone/GetZoneListForFilter")
                .then(response => {
                    let data = response.data;
                    for (let i = 0; i < data.length; i++) {
                        this.domainOptions.push({
                            domainName: data[i]
                        });
                    }
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get exit page data
        async getExitPageData() {
            this.$vs.loading();
            let uniqueVisitor = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsExitPage/GetExitPageStats", uniqueVisitor)
                .then(response => {
                    let data = response.data;
                    this.exitPageData = data.Page_Data;
                    this.exitPageData.map(function (e) {
                        e.sortablePagePercent = parseFloat(e.Page_Percent.substring(0,e.Page_Percent.length - 1));
                        e.sortableBouncePercent = parseFloat(e.Bounce_Percent.substring(0,e.Bounce_Percent.length - 1));
                        e.sortableBounceRatePercent = parseFloat(e.Bounce_Rate_Percent.substring(0,e.Bounce_Rate_Percent.length - 1));
                        return e;
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //get detailed page data
        async getDetailedData(category, subcategory) {
            this.$vs.loading();
            let uniqueVisitor = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies,
                Category: category,
                SubCategory: subcategory
            };
            await this.axios
                .post("/ws/AnalyticsDetail/GetDetailData", uniqueVisitor)
                .then(response => {
                    let data = response.data;
                    this.pageViews.name = data.Page_Views.StatName;
                    this.pageViews.startValue = data.Page_Views.StatStartValue;
                    this.pageViews.endValue = data.Page_Views.StatEndValue;
                    this.pageViews.percentage = data.Page_Views.StatChangePercent;

                    this.analyticsUniqueVisitors.name = data.Unique_Visitors.StatName;
                    this.analyticsUniqueVisitors.startValue = data.Unique_Visitors.StatStartValue;
                    this.analyticsUniqueVisitors.endValue = data.Unique_Visitors.StatEndValue;
                    this.analyticsUniqueVisitors.percentage = data.Unique_Visitors.StatChangePercent;

                    this.dateStart = data.Date_Start;
                    this.dateEnd = data.Date_End;
                    this.zoneName = data.Zone_Name;
                    this.category = data.Category;
                    this.subCategory = data.SubCategory;
                    this.ISPIncludeMessage = data.ISP_Include_Message;
                    this.analyticsActive = true;

                    let x = [];
                    let FirstGroup = [];
                    let secondGroup = [];

                    data.Default_Chart.forEach(element => {
                        x.push(element.x);
                        FirstGroup.push(element.FirstGroup);
                        secondGroup.push(element.SecondGroup);
                    });

                    this.lineAreaChartSplineSeriesDataOne = FirstGroup
                    this.lineAreaChartSplineSeriesDataTwo = secondGroup
                    this.lineAreaChartSplineOptionsXaxis = x

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        }
    },
};
</script>
